import styles from './Form.module.css'
import { Box, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'

const Form1 = () => {
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ display: "flex" }}>
                <TextField
                    required
                    id="outlined-required"
                    label="Company Name"
                    fullWidth
                    size='small'

                />
                <TextField
                    required
                    id="outlined-required"
                    label="Website"
                    fullWidth
                    size='small'
                />
            </div>
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ height: 40, ml: 1, color: "#939596" }}
                    fullWidth
                    defaultValue="Select Industry"
                >
                    <MenuItem value={"Select Industry"}>Select Industry</MenuItem>
                    <MenuItem value={"Select Industry"}>Select Industry</MenuItem>
                    <MenuItem value={"Select Industry"}>Select Industry</MenuItem>
                </Select>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ height: 40, ml: 1, color: "#939596" }}
                    fullWidth
                    defaultValue="Select Facility Management Company"
                >
                    <MenuItem value={"Select Facility Management Company"}>Select Facility Management Company</MenuItem>
                    <MenuItem value={"Select Facility Management Company"}>Select Facility Management Company</MenuItem>
                    <MenuItem value={"Select Facility Management Company"}>Select Facility Management Company</MenuItem>
                </Select>
            </div>

            <div style={{ display: "flex" }}>
                <TextField
                    required
                    id="outlined-required"
                    label="Company Name"
                    fullWidth
                    size='small'

                />
                <TextField
                    required
                    id="outlined-required"
                    label="Website"
                    fullWidth
                    size='small'
                />
            </div>
        </Box>
    )
}

export default Form1