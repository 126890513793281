import { Box } from "@mui/material"
import "./AddClient.module.css"
import Form1 from "../Forms/Form1"

interface AddClientProps {
  drawerWidth: number
}
const AddClient = (props: AddClientProps) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form1/>
    </Box>
  )
}

export default AddClient