import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";

import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import styles from "./Dashboard.module.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AddClient from "App/Components/AddClient/AddClient";
import ViewClient from "App/Components/ViewClient/ViewClient";

const drawerWidth = 300;
const Dashboard = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  }

  const drawer = (
    <div className={styles.sideBar}>
      <div style={{ background: "#FFFFFF" }}>
        <div className={styles.sidebarTop}>
          <h3>Company Name</h3>
          <form className={styles.searchBarTop}>
            <input type="text" placeholder="Search Module" />
            <SearchIcon />
          </form>
        </div>

        <div className={styles.sidebarLinks}>
          <h4>Client Master</h4>
          <div
            className={styles.sidebarLink}
            onClick={()=>setMobileOpen(false)}
            style={
              location.pathname === "/dashboard" ? {
                backgroundColor: "#153AC7",
              } : location.pathname === "/" ? {
                backgroundColor: "#153AC7",
              } : {}
            }
          >
            <Link
              to="/dashboard"
              style={
                location.pathname === "/dashboard" ? {
                  color: "#FFFFFF"
                } : location.pathname === "/" ? {
                  color: "#FFFFFF"
                } : {}
              }
            >
              View Clients
            </Link>
          </div>
          <div
            className={styles.sidebarLink}
            onClick={()=>setMobileOpen(false)}
            style={
              location.pathname === "/dashboard/add-client" ? {
                backgroundColor: "#153AC7",
              } : {}
            }
          >
            <Link
              to="/dashboard/add-client"
              style={
                location.pathname === "/dashboard/add-client" ? {
                  color: "#FFFFFF"
                } : {}
              }
            >
              Add Client
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.sideBarBottom}>
        <div className={styles.useInfo}>
          <div className={styles.userImage}>
            <img
              src="https://avatars.githubusercontent.com/u/72749432?s=100&v=4"
              alt="user"
            />
          </div>
          <div className={styles.userDetails}>
            <h4>Abhishek Bhardwaj</h4>
            <p>abhishektest@gmail.com</p>
          </div>
        </div>
        <div className={styles.logoutBtn}>
          <LogoutIcon onClick={handleLogout} />
        </div>
      </div>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "ghostwhite",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon style={{ color: "#000", fontSize: "35px" }} />
          </IconButton>
          <div className={styles.header}>

            {
              location.pathname === "/" || location.pathname === "/dashboard" ? (
                <div className={styles.headerText}>
                  <h3>View Clients</h3>
                  <p>
                    Client Master <span> / View Clients</span>
                  </p>
                </div>
              ) : (
                <div className={styles.headerText}>
                  <h3>Add Client</h3>
                  <p>
                    Client Master <span> / Add Client </span>
                  </p>
                </div>
              )
            }

            <div className="styles notificatinsIcons">
              <NotificationsNoneRoundedIcon />
            </div>
          </div>
        </Toolbar>

      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {
          location.pathname === "/" || location.pathname === "/dashboard" ? (
            <ViewClient drawerWidth={drawerWidth} />
          ) : (
            <AddClient drawerWidth={drawerWidth} />
          )
        }

      </Box>
    </Box>
  );
};

export default Dashboard;
