import {
  Box,
  Select,
  SelectChangeEvent,
  MenuItem,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  Pagination,
  Stack
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Visibility from '@mui/icons-material/Visibility';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "./ViewClient.module.css"
import { useState } from "react";

interface ViewClientProps {
  drawerWidth: number,
}

const tableData = [
  {
    id: 1,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek Test",
    sites: "4",
    facilitator: "--",
    tenants: "11",
    tenantGroups: "5",
  },
  {
    id: 2,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test Abhishek",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek B",
    facilitator: "--",
    sites: "2",
    tenants: "--",
    tenantGroups: "8",
  },
  {
    id: 3,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek Test",
    facilitator: "--",
    sites: "5",
    tenants: "11",
    tenantGroups: "5",
  },
  {
    id: 4,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test Abhishek",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek B",
    facilitator: "--",
    sites: "15",
    tenants: "5",
    tenantGroups: "8",
  },
  {
    id: 5,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek Test",
    sites: "4",
    facilitator: "--",
    tenants: "11",
    tenantGroups: "5",
  },
  {
    id: 6,
    img: "https://avatars.githubusercontent.com/u/72749432?s=100&v=4",
    name: "Test",
    email: "abhishek@gmail.com",
    phone: "+91 1234567890",
    contactPerson: "Abhishek Test",
    facilitator: "--",
    sites: "5",
    tenants: "11",
    tenantGroups: "5",
  },
]

const ViewClient = (props: ViewClientProps) => {
  const [dropDown, setDropDown] = useState('Columns');

  const handleChange = (event: SelectChangeEvent) => {
    setDropDown(event.target.value as string);
  };

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2,
        backgroundColor: "#FFFFFF",
      }}
    >
      <div className={styles.viewClientHeadaer}>
        <div className={styles.viewClientLeft}>
          <form className={styles.searchBar}>
            <input type="text" placeholder="Search" />
            <SearchIcon />
          </form>
        </div>

        <div className={styles.viewClientRight}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dropDown}
            onChange={handleChange}
            sx={{ width: 110, height: 30, mb: 2, color: "#939596", fontSize: 14, fontWeight: 400, }}
          >
            <MenuItem value={"Columns"}>Columns</MenuItem>
            <MenuItem value={"Rows"}>Rows</MenuItem>
          </Select>

          <Button
            variant="outlined"
            color="primary"
            sx={{
              height: 31,
              width: 100,
              marginLeft: 1,
              textTransform: "none",
              fontWeight: "700",
            }}
          >
            <span> <FileDownloadOutlinedIcon /></span>
            <span>Export</span>
          </Button>

        </div>

      </div>

      <div className={styles.viewClientTable}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, mt: 2 }} size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: "#F8F9FD" }}>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell >Email Address</TableCell>
                <TableCell >Phone No</TableCell>
                <TableCell >Contact Person</TableCell>
                <TableCell>Facilitator</TableCell>
                <TableCell >Sites</TableCell>
                <TableCell >Tenants</TableCell>
                <TableCell >Tenant Groups</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ display: "flex", alignItems: "center", border: 0 }}
                  >
                    <img src={row.img} alt={row.name} />
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ border: 0 }}>{row.email}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.phone}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.contactPerson}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.facilitator}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.sites}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.tenants}</TableCell>
                  <TableCell sx={{ border: 0 }}>{row.tenantGroups}</TableCell>
                  <TableCell sx={{ border: 0 }} align="center">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                        },
                      }}
                    >

                      <MenuItem onClick={handleClose} sx={{ color: "#030037", fontSize: "14px", fontWeight: "400", }} disableRipple>
                        <Visibility sx={{ mr: 1 }} />
                        View Details
                      </MenuItem>

                      <MenuItem onClick={handleClose} sx={{ color: "#030037", fontSize: "14px", fontWeight: "400" }} disableRipple>
                        <EditIcon sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>

                      <MenuItem onClick={handleClose} sx={{ color: "#030037", fontSize: "14px", fontWeight: "400" }} disableRipple>
                        <BlockIcon sx={{ mr: 1 }} />
                        Block Access
                      </MenuItem>

                      <MenuItem onClick={handleClose} sx={{ color: "#030037", fontSize: "14px", fontWeight: "400", }} disableRipple>
                        <DeleteIcon sx={{ mr: 1 }} />
                        Delete
                      </MenuItem>

                    </Menu>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack spacing={2} sx={{ p: 2 }}>
            <Pagination count={40} variant="outlined" shape="rounded" />
          </Stack>
        </TableContainer>
      </div>
    </Box>
  )
}

export default ViewClient